<template>
  <component
    :is="resolveLayoutVariant"
    :class="`skin-variant--${skinVariant}`"
  >
    <transition
      :name="routeTransition"
      mode="out-in"
      appear
    >
      <router-view
        :key="$route.fullPath"
      />
    </transition>

    <v-snackbar
      bottom
      right
      :value="updateExists"
      :timeout="-1"
    >
      バージョンアップデートがあります。
      <v-btn
        text
        :ripple="false"
        @click="refreshApp"
      >
        更新する
      </v-btn>
    </v-snackbar>
  </component>
</template>

<script>
import {
  computed,
  ref,
  onMounted,
  getCurrentInstance,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'
// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutAdmin from '@/layouts/variants/LayoutAdmin.vue'
import LayoutWaiter from '@/layouts/variants/LayoutWaiter.vue'
import LayoutCast from '@/layouts/variants/LayoutCast.vue'

import useCurrentData from '@/views/composable/useCurrentData'
import 'vue2-animate/dist/vue2-animate.min.css'

import MyAbilityApi from '@/api/v2/security/MyAbility'

export default {
  components: {
    LayoutBlank,
    LayoutAdmin,
    LayoutWaiter,
    LayoutCast,
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const { route } = useRouter()
    const { currentUser } = useCurrentData()

    const { skinVariant, routeTransition } = themeConfig.app
    const refreshing = ref(false)
    const worker = ref(null)
    const updateExists = ref(false)

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'

      const layoutNames = {
        admin: 'layout-admin',
        waiter: 'layout-waiter',
        cast: 'layout-cast',
      }
      const layoutName = layoutNames[currentUser.value?.role || route.value.meta.layout]

      if (layoutName) return layoutName

      return null
    })

    useDynamicVh()

    const showRefreshUI = e => {
      if (e.detail) {
        worker.value = e.detail
        updateExists.value = true
      }
    }

    document.addEventListener('swUpdated', showRefreshUI, { once: true })
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener(
        'controllerchange',
        () => {
          if (refreshing.value) return
          refreshing.value = true
          window.location.reload()
        },
      )
    }

    const refreshApp = () => {
      updateExists.value = false

      if (!worker.value) {
        // if no worker return without post messaging
        return
      }
      worker.value.waiting.postMessage({ type: 'SKIP_WAITING' })
    }

    onMounted(async () => {
      if (currentUser.value) {
        const res = await MyAbilityApi.getMyAbilities()
        if (res?.data?.abilities) {
          const { abilities } = res.data
          vm.$ability.update(abilities)
          localStorage.setItem('userAbilities', JSON.stringify(abilities))
        }

        window.userGuiding.identify(currentUser.value.id, {
          name: currentUser.value.name,
          email: currentUser.value.email,
          clubId: currentUser.value.clubId,
          clubName: currentUser.value.name,
          clubSlug: currentUser.value.club,
          env: process.env.NODE_ENV,
        })
      }
    })

    return {
      updateExists,
      refreshApp,
      resolveLayoutVariant,
      skinVariant,
      routeTransition,
      currentUser,
    }
  },
}
</script>
